import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import apiUrl from '../../../../apis/api.url'
import { getCommonData } from '../../../../apis/common.apis'
import { useMasterData } from '../../../../store/masterDataStore'
import CustomDrawer from '../../../common/drawer'
import Icons from '../../../common/icons'
import FormFieldView from '../../../common/inputs/FormFieldView'
import FormBuilder from '../../formBuilder'
import { useAddPayment } from './api'
import { ACCEPTED_IMAGE_TYPES, paymentSchema, PaymentSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: (flag?: boolean) => void
  edit: boolean
  id: string
  url?: string

  handleCallback?: () => void
}

export default function ManagePayment({
  isDrawerOpen,
  handleClose,
  edit = false,

  handleCallback,
  id,
  url = apiUrl.PAYMENT, // handleCallback,
}: Props) {
  const { masterData } = useMasterData()
  const [paymentData, setPaymentData] = useState<any>({ transactions: [] })
  const [fieldEdit, setFieldEdit] = useState(edit)
  const [isEditable, setIsEditable] = useState(false)
  const [count, setCount] = useState(1)
  const [paymentFields, setPaymentFields] = useState<any>([])
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    spacing = 4,
    type = 'text'
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
    spacing: spacing,
  })
  const handleGetDetails = async () => {
    const { data: resp } = await getCommonData(`${url}/${id}`)
    setPaymentData(resp)
    setIsEditable(
      resp.payment_verification_status?.code !== 'not_verified' ? false : true
    )
    // setPaymentDetails(data?.results)
  }

  const formBuilderProps = [
    ...(paymentData?.payment_type !== 'link'
      ? [
          {
            ...textField(
              'transaction_date',
              'Transaction Date',
              'Enter transaction date',
              true
            ),
            maxDate: new Date(),
            disabled: true,
            value: paymentData?.transactions[0]?.transaction_date
              ? moment(paymentData?.transactions[0]?.transaction_date).format(
                  'DD-MM-YYYY'
                )
              : '--',
            type: 'date',
          },
          {
            ...textField(
              'transaction_id',
              'Transaction ID',
              'Enter transaction id',
              true
            ),
          },
          {
            ...textField('amount', 'Amount', 'Amount', true, 8, 'number'),
            type: 'number',
            id: 'amount',
            disabled: true,
          },
          {
            name: 'payment_method_name',
            label: 'Payment Method',
            required: true,
            data: masterData?.payment_methods,
            async: false,
            id: 'payment_method_id',
            paginationEnabled: false,
            descId: 'id',
            initialLoad: true,
            desc: 'name',
            type: 'custom_search_select',
            placeholder: 'Enter Payment Method',
          },
        ]
      : []),
    {
      ...textField(
        'internal_notes',
        'Internal Notes',
        'Enter internal notes',
        false
      ),
      type: 'textarea',
      disabled: false,
    },
    ...(paymentData?.payment_type !== 'link'
      ? [
          {
            ...textField(
              'receipt_number',
              'Receipt number',
              'Enter Receipt number',
              true
            ),
          },
          {
            name: 'payment_document',
            required: false,
            label: 'Attach file',
            id: 'payment_document',
            supportedExtensions: ACCEPTED_IMAGE_TYPES,
            supportedFiles: ACCEPTED_IMAGE_TYPES,

            type: 'file_upload',
          },
        ]
      : []),
  ]

  const methods = useForm<PaymentSchema>({
    resolver: zodResolver(paymentSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const handleSubmission = () => {
    methods.reset()
    setCount(count + 1)
    setFieldEdit(false)
    handleCallback?.()
    handleClearAndClose()
  }
  const { mutate, isLoading } = useAddPayment(handleSubmission)
  const onSubmit = (data: any) => {
    const formData = new FormData()

    Object.keys(data)?.forEach((item: string) => {
      if (item === 'transaction_date') {
        formData.append(
          'transaction_date',
          moment(data.transaction_date).format('YYYY-MM-DD')
        )
      } else if (item === 'payment_document') {
        if (
          typeof data?.payment_document !== 'string' &&
          data.payment_document !== undefined
        ) {
          formData.append('payment_document', data?.payment_document)
        }
      } else if (item === 'is_advance') {
        formData.append(
          'is_advance',
          data.is_advance === 'true' ? 'True' : 'False'
        )
      } else if (item === 'receipt_number') {
        if (paymentData?.payment_type !== 'link') {
          formData.append('receipt_number', data?.receipt_number)
        }
      } else {
        if (item !== 'payment_method_name') {
          formData.append(item, data[item])
        }
      }
    })

    // formData.append('payment_type', 'manual')
    mutate({
      input: formData,
      id: id,
      url: url,
    })
  }

  // useEffect(() => {
  //   handleCallback?.()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data])

  const { handleSubmit } = methods

  const handleClearAndClose = (flag = false) => {
    methods.reset({})

    if (edit) {
      setFieldEdit(false)
      handleClose(flag)
    } else {
      if (fieldEdit) {
        setFieldEdit(false)
        setCount((cn) => cn + 1)
      } else {
        handleClose(flag)
      }
    }
  }
  const managePaymentFields = () => {
    const fields = [
      ...(!fieldEdit
        ? [
            {
              label: 'Account',
              required: true,
              show: true,
              value: paymentData?.order?.account?.business_name ?? '--',
            },
            {
              label: 'Contact',
              required: true,
              show: true,
              value: paymentData?.order?.contact?.customer_number ?? '--',
            },
            {
              label: 'Order ID',
              required: true,
              show: true,
              value: paymentData?.order?.order_number ?? '--',
            },
            {
              label: 'Transaction Date',
              required: true,
              show: true,
              value: paymentData?.transactions[0]?.transaction_date
                ? moment(paymentData?.transactions[0]?.transaction_date).format(
                    'DD-MM-YYYY'
                  )
                : '--',
            },
            {
              label: 'Transaction ID',
              required: true,
              show: true,
              value: paymentData?.transactions[0]?.transaction_id ?? '--',
            },
          ]
        : []),

      {
        label: 'Amount',
        required: true,
        value: paymentData?.amount ?? '--',
        show:
          paymentData?.payment_type === 'link' ||
          (!fieldEdit && paymentData?.payment_type !== 'link'),
      },
      {
        label: 'Payment Method',
        required: true,
        value: paymentData?.payment_method?.display_name ?? '--',
        show:
          paymentData?.payment_type === 'link' ||
          (!fieldEdit && paymentData?.payment_type !== 'link'),
      },
      ...(fieldEdit && paymentData?.payment_type === 'link'
        ? [
            {
              label: 'Message to Customer',
              required: false,
              show: true,
              value: paymentData?.message ?? '--',
            },
          ]
        : []),
      ...(!fieldEdit
        ? [
            {
              label: 'Receipt Number',
              show: true,
              value: paymentData?.transactions[0]?.receipt_number ?? '--',
            },

            {
              label: 'Advance Payment',
              required: true,
              show: true,
              value: paymentData?.transactions[0]?.is_advance ? 'Yes' : 'No',
            },
            {
              label: 'Internal Notes',
              show: true,
              value: paymentData?.internal_notes ?? '--',
            },
            {
              label: 'Order Status',
              required: true,
              show: true,
              value: paymentData?.order?.order_status?.name ?? '--',
            },
          ]
        : []),
    ]
    setPaymentFields(fields)
  }
  useEffect(() => {
    if (Object.keys(paymentData).length) {
      managePaymentFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData, edit, fieldEdit])
  useEffect(() => {
    if (url && id && isDrawerOpen) {
      handleGetDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, id, isDrawerOpen, count])
  const manageActions = () => {
    setFieldEdit(true)
  }
  useEffect(() => {
    setFieldEdit(edit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit])
  const handleFillData = () => {
    methods.reset({
      transaction_date: paymentData?.transactions[0]?.transaction_date
        ? new Date(paymentData?.transactions[0]?.transaction_date)
        : undefined,
      transaction_id: paymentData?.transactions[0]?.transaction_id ?? undefined,
      amount: paymentData?.amount ? Number(paymentData?.amount) : 0,
      payment_method_id: paymentData?.payment_method?.id,
      payment_method_name: paymentData?.payment_method?.display_name,
      internal_notes: paymentData?.internal_notes ?? '',
      receipt_number:
        paymentData?.payment_type !== 'link'
          ? paymentData?.transactions[0]?.receipt_number
          : paymentData?.transactions[0]?.transaction_id,

      is_advance: paymentData?.transactions[0]?.is_advance ? 'true' : 'false',
      payment_document: undefined,
    })
  }
  useEffect(() => {
    handleFillData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData])
  const handleOpenFile = (url?: string) => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  return (
    <>
      {
        <CustomDrawer
          className="formDrawer"
          open={isDrawerOpen}
          actionLabel={fieldEdit ? 'Save' : 'Edit'}
          handleClose={() => handleClearAndClose()}
          actionLoader={isLoading}
          handleSubmit={
            !isEditable
              ? undefined
              : !fieldEdit
              ? manageActions
              : handleSubmit((data) => onSubmit(data))
          }
          title={
            fieldEdit && isEditable
              ? `Edit Payment (${
                  paymentData?.payment_type === 'link' ? 'Online' : 'Offline'
                })`
              : 'View Payment'
          }
          contentBg={true}
        >
          <div className="flex flex-col  ">
            {paymentFields?.filter((item: any) => item.show)?.length ? (
              <div
                className={`py-4 px-5 bg-cardWrapperBg   flex flex-col gap-3 `}
              >
                {paymentFields?.map((item: any, i: number) => (
                  <React.Fragment key={`${i}`}>
                    {item.show && (
                      <FormFieldView
                        label={item?.label}
                        type={item?.type}
                        value={item.value}
                        required={item.required}
                        fullwidth
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            ) : null}

            <FormProvider {...methods}>
              {fieldEdit && (
                <div className={'flex flex-col gap-4 px-5 pt-4'}>
                  <FormBuilder data={formBuilderProps} edit={!isEditable} />
                </div>
              )}

              {(!fieldEdit || paymentData?.payment_type !== 'link') && (
                <div
                  className={`px-5 pb-4 ${
                    !fieldEdit ? 'bg-cardWrapperBg' : ''
                  } `}
                >
                  {!fieldEdit && (
                    <div className=" ">
                      <label className={`labels label-text`}>Attachments</label>
                    </div>
                  )}
                  {!methods.watch().payment_document && (
                    <>
                      {paymentData?.transactions[0]?.payment_document ? (
                        <div className="flex items-center justify-between gap-1.5  rounded-sm">
                          <Icons
                            name="paper-clip"
                            className="iconWidthSm iconBlack"
                          />
                          <p
                            onClick={() =>
                              handleOpenFile(
                                paymentData?.transactions[0]?.payment_document
                                  ?.url
                              )
                            }
                            className={`flex-1  text-sm font-medium ${
                              paymentData?.transactions[0]?.payment_document
                                ?.url
                                ? 'cursor-pointer text-link'
                                : 'cursor-default text-primaryText'
                            }`}
                          >
                            {paymentData?.transactions[0]?.payment_document
                              ?.name || 'Attachment'}
                          </p>
                        </div>
                      ) : (
                        <>{!fieldEdit ? <>--</> : null}</>
                      )}
                    </>
                  )}
                </div>
              )}
              {fieldEdit && (
                <div className={`customRadioButton w-auto px-5 pt-4`}>
                  <label className={`labels label-text`}>Advance Payment</label>
                  <div className="customRadio-field relative flex items-center gap-4">
                    <div className="flex item-center gap-1 my-2 cursor-pointer">
                      <input
                        type="radio"
                        value="true"
                        id="is_advance"
                        {...methods.register('is_advance')}
                      />
                      <label htmlFor="is_advance" className="cursor-pointer">
                        Yes
                      </label>
                    </div>
                    <div className="flex item-center gap-1 my-2 cursor-pointer">
                      <input
                        type="radio"
                        value="false"
                        id="is_advance2"
                        {...methods.register('is_advance')}
                      />
                      <label htmlFor="is_advance2" className="cursor-pointer">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </FormProvider>
          </div>
        </CustomDrawer>
      }
    </>
  )
}
