import { zodResolver } from '@hookform/resolvers/zod'
// import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import moment from 'moment'
import { z } from 'zod'
import FormBuilder from '../../../components/app/formBuilder'
import CustomDrawer from '../../../components/common/drawer'
import { useAuthStore } from '../../../store/authStore'
import { fetchSupplierCategoryData } from '../../supplierCategories/api'
import { fetchSupplierData } from '../../suppliers/api'
import {
  fetchFranchiseeBankAccount,
  getPaymentOutList,
  getPaymentOutMethods,
  addPayout,
  updatePayout,
  deletePayoutFile,
} from '../api'
import { ACCEPTED_IMAGE_TYPES, handleSchema } from './schema'
import FormFieldView from '../../../components/common/inputs/FormFieldView'
import { getErrorMessage } from '../../../utilities/parsers'
import { useSnackbarManager } from '../../../components/common/snackbar'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  handleCallback?: () => void
  id?: string
  rowData?: any
  viewMode?: boolean
  edit?: boolean
  setViewMode?: (value: boolean) => void
}

export default function AddPayouts({
  isDrawerOpen,
  handleClose,
  handleCallback,
  rowData,
  viewMode,
  edit,
  setViewMode,
}: Props) {
  const [payoutType, setPayoutType] = useState<any>([])
  const [bankAccounts, setBankAccounts] = useState<any>([])
  const [supplierPage, setSupplierPage] = useState<any>(1)
  const [supplierCategoryPage, setSupplierCategoryPage] = useState<any>(1)
  const [method, setMethod] = useState()
  // const [supplierCategoriess, setSupplierCategoriess] = useState<any>([])
  const [payoutMethod, setPayoutMethod] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const paymentSchema = handleSchema(method, rowData)
  type PaymentSchema = z.infer<typeof paymentSchema>
  const franchisee = useAuthStore.getState().franchisee
  const { enqueueSnackbar } = useSnackbarManager()
  //   const todayDate = moment().toDate()
  //   const formatedDate = moment(todayDate).format('DD-MM-YYYY')
  const onInit = () => {
    return {}
  }

  const methods = useForm<PaymentSchema>({
    resolver: zodResolver(paymentSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })

  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPayoutMethodsList = async () => {
    await getPaymentOutMethods({ is_active: true }).then((res) => {
      setPayoutMethod(res?.data)
    })
  }
  const getPayoutList = async () => {
    await getPaymentOutList({ show_in_franchisee: true }).then((res) => {
      setPayoutType(res?.data)
    })
  }
  const getBankAccountList = () => {
    fetchFranchiseeBankAccount({
      id: franchisee?.id as string,
      input: { page: 1, page_size: 30 },
    }).then((res) => {
      setBankAccounts(res?.data?.results)
    })
  }
  const getSuppliers = async (value: any) => {
    const resp = await fetchSupplierData({
      search: value ?? '',
      page: supplierPage,
      ...(methods.watch().supplier_category_id &&
      methods.watch().supplier_category_id !== ''
        ? {
            category_id: methods.watch().supplier_category_id,
          }
        : {}),
    })

    const next = resp?.next ? supplierPage + 1 : 0
    setSupplierPage(next)
    return resp?.results?.map((item: any) => ({
      ...item,
      name: item?.name,
    }))
  }
  const getSupplierCategories = async (value: any) => {
    const resp = await fetchSupplierCategoryData({
      search: value ?? '',
      page: 1,
    })
    const next = resp?.next ? supplierPage + 1 : 0
    setSupplierCategoryPage(next)
    return resp?.results?.map((item: any) => ({
      ...item,
      name: item?.name,
    }))
  }
  const extractAccount = (account_name: string) => {
    bankAccounts.map((item: any) => {
      if (item.account_name == account_name) {
        methods.reset({
          ...methods.watch(),
          bank_account_id: item.id,
          bank_account_name: item.account_display_name
            ? item.account_display_name
            : item.account_name,
        })
      }
    })
  }
  useEffect(() => {
    if (isDrawerOpen) {
      if (!viewMode) {
        getBankAccountList()
        getPayoutMethodsList()
        getPayoutList()
      }
      if (!viewMode && !edit) {
        methods.reset({})
      } else {
        methods.reset({
          paymentout_type: rowData?.paymentout_type?.id,
          paymentout_type_name: rowData?.paymentout_type?.display_name,
          payment_date: rowData?.payment_date
            ? moment(rowData?.payment_date, 'YYYY-MM-DD').toString()
            : '',
          amount: rowData?.amount ? parseFloat(rowData?.amount) : 0,
          paymentout_method: rowData?.paymentout_method?.id,
          paymentout_method_name: rowData?.paymentout_method?.display_name,
          transaction_number: rowData?.transaction_number,
          receipt_number: rowData?.receipt_number,
          supplier_id: rowData?.supplier?.id,
          supplier_name: rowData?.supplier?.name,
          supplier_category_id: rowData?.supplier_category?.id,
          supplier_category_name: rowData?.supplier_category?.name,
          remark: rowData?.remark,
        })
      }
    }
  }, [rowData, viewMode, edit, isDrawerOpen])

  useEffect(() => {
    extractAccount(rowData?.account_name)
  }, [bankAccounts])

  const paymentMethodCallback = (d: any) => {
    setMethod(d.code)
    if (d.code === 'cash') {
      methods.clearErrors('bank_account_id')
      methods.clearErrors('bank_account_name')
    }
  }
  const handleDeleteFile = () => {
    deletePayoutFile(rowData?.id)
      .then(() => {
        enqueueSnackbar('File removed successfully', { variant: 'success' })
        handleCallback?.()
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, { variant: 'error' })
      })
  }
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    type = 'text',
    disabled = false
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
    ...(disabled ? { disabled: true } : {}),
  })

  const handleSupplierCategoryCallback = () => {
    methods.setValue('supplier_name', '')
    methods.setValue('supplier_id', '')
  }
  const formBuilderProps = [
    {
      name: 'payout_id',
      label: 'Payout ID',
      value: rowData?.unique_id,
      hidden: !viewMode,
    },
    {
      name: 'paymentout_type_name',
      label: 'Payment Type',
      required: true,
      data: payoutType,
      async: false,
      id: 'paymentout_type',

      descId: 'id',
      // initialLoad: true,
      desc: 'name',
      type: 'custom_select',
      placeholder: 'Enter Payment Type',
      value: rowData?.paymentout_type?.name,
    },
    {
      ...textField(
        'payment_date',
        'Date of Payment',
        'Enter transaction date',
        true
      ),
      maxDate: new Date(),
      type: 'date',
      value: rowData?.payment_date,
    },
    {
      ...textField('amount', 'Amount', 'Amount', true, 'number'),
      type: 'number',
      id: 'amount',
      value: rowData?.amount,
    },
    {
      name: 'paymentout_method_name',
      label: 'Mode of Payment',

      data: payoutMethod,
      required: true,
      id: 'paymentout_method',
      handleCallBack: paymentMethodCallback,
      descId: 'id',
      // initialLoad: true,
      desc: 'name',
      type: 'custom_select',
      placeholder: 'Enter Mode of Payment',
      value: rowData?.paymentout_method?.name,
    },
    {
      ...textField(
        'transaction_number',
        'Transaction number',
        'Enter Transaction number',
        true
      ),
      value: rowData?.transaction_number,
    },
    {
      ...textField(
        'receipt_number',
        'Receipt number',
        'Enter Receipt number',
        true
      ),
      value: rowData?.receipt_number,
    },
    {
      name: 'supplier_category_name',
      label: 'Supplier Category',
      nextBlock: supplierCategoryPage,
      paginationEnabled: true,
      async: true,
      getData: getSupplierCategories,
      required: true,
      id: 'supplier_category_id',
      handleCallBack: handleSupplierCategoryCallback,
      descId: 'id',
      // initialLoad: true,

      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter Supplier Category',
      value: rowData?.supplier_category?.name,
    },
    {
      name: 'supplier_name',
      label: viewMode ? 'Supplier' : 'Suppliers',
      nextBlock: supplierPage,
      paginationEnabled: true,
      async: true,
      getData: getSuppliers,
      required: true,
      id: 'supplier_id',

      descId: 'id',
      initialLoad: true,

      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter Supplier',
      value: rowData?.supplier?.name,
    },

    {
      name: 'bank_account_name',
      label: 'Bank Account',

      data: bankAccounts,
      required:
        method === 'cash' || rowData?.paymentout_method?.code === 'cash'
          ? false
          : true,
      id: 'bank_account_id',

      descId: 'id',
      // initialLoad: true,

      desc: 'account_display_name',
      type: 'custom_select',
      placeholder: 'Enter bank account',
      hidden: viewMode,
      disabled: edit,
    },
    {
      name: 'name_in_account',
      label: 'Name in Account',
      value: rowData?.account_name,
      hidden: !viewMode,
    },
    {
      name: 'account_type',
      label: 'Account Type',
      value: rowData?.account_type,
      hidden: !viewMode,
    },
    {
      name: 'account_number',
      label: 'Account Number',
      value: rowData?.account_number,
      hidden: !viewMode,
    },
    {
      name: 'bank_name',
      label: 'Bank Name',
      value: rowData?.bank_name,
      hidden: !viewMode,
    },
    {
      name: 'branch_name',
      label: 'Branch Name',
      value: rowData?.branch_name,
      hidden: !viewMode,
    },
    {
      name: 'ifsc_code',
      label: 'IFSC Code',
      value: rowData?.ifsc_code,
      hidden: !viewMode,
    },
    {
      ...textField('remark', 'Remarks', 'Enter Remarks', false),
      type: 'textarea',
      disabled: false,
      value: rowData?.remark,
    },

    {
      name: 'payment_document',
      required: false,
      id: 'payment_document',
      selectedFiles: rowData?.original_document
        ? { name: rowData?.original_document, link: rowData?.payment_document }
        : '',
      handleDeleteFile: handleDeleteFile,
      descId: 'id',
      type: 'file_upload',
      label: viewMode ? 'Payment Document' : '',
      acceptedFiles: 'JPEG,JPG,PNG,PDF',
      supportedExtensions: ACCEPTED_IMAGE_TYPES,
      supportedFiles: ACCEPTED_IMAGE_TYPES,
      link: rowData?.payment_document,
      value: rowData?.original_document,
    },
  ]

  const handleSubmission = () => {
    methods.reset()
    handleCallback?.()
    handleClose()
  }

  const onSubmit = (data: PaymentSchema) => {
    setIsLoading(true)
    const input: any = {
      amount: data?.amount ?? '',
      paymentout_method: data?.paymentout_method ?? '',
      payment_date: data?.payment_date
        ? moment(data?.payment_date).format('YYYY-MM-DD')
        : '',
      transaction_number: data?.transaction_number ?? '',
      paymentout_type: data?.paymentout_type ?? '',
      receipt_number: data?.receipt_number ?? '',
      bank_account_id: data?.bank_account_id ?? '',
      supplier: data?.supplier_id ?? '',
      supplier_category: data?.supplier_category_id ?? '',
      remark: data?.remark ?? '',
      franchisee: franchisee?.id,
      ...(data?.payment_document
        ? { payment_document: data?.payment_document }
        : {}),
    }
    const payload = new FormData()
    Object.keys(input).forEach((k) => {
      payload.append(k, input[k])
    })
    const apiFun = rowData?.id
      ? updatePayout(rowData?.id, payload)
      : addPayout(payload)
    apiFun
      .then(() => {
        enqueueSnackbar(
          getErrorMessage(
            rowData?.id
              ? 'Payout Updated Successfully'
              : 'Payout Created Successfully'
          ),
          {
            variant: 'success',
          }
        )
        handleSubmission()
        setIsLoading(false)
      })
      .catch((err) => {
        enqueueSnackbar(getErrorMessage(err.response.data.error), {
          variant: 'error',
        })
        setIsLoading(false)
      })
  }

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    setViewMode?.(false)
    handleClose()
  }
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      actionLoader={isLoading}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
      title={edit ? 'Edit Payment Out' : 'Add Payment Out'}
      disableSubmit={isLoading}
      hideSubmit={viewMode}
    >
      <div className="flex flex-col gap-4">
        {!viewMode ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={true} />
          </FormProvider>
        ) : (
          formBuilderProps.map((data: any) => (
            <FormFieldView
              value={data?.value}
              label={data?.label}
              key={data?.id}
              type={data?.type}
              link={data?.link}
              hidden={data?.hidden}
            />
          ))
        )}
      </div>
    </CustomDrawer>
  )
}
